<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, provide } from "vue";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { messaging, token } from "@/core/plugins/firebase";
import { useRouter } from "vue-router";

// load all stores
import { useCategories } from "./store/categories";
import { useCustomers } from "./store/customers";
import { useMenus } from "./store/menus";
import { useOptions } from "./store/options";
import { useProducts } from "./store/products";
import { useStaffs } from "./store/staffs";
import { useOffers } from "./store/offers";
import { useSettings } from "./store/settings";
import { useSales } from "./store/sales";
import { useAuth } from "./store/auth";
import ApiService from "./core/services/ApiService";

export default defineComponent({
  name: "app",
  setup() {
    const router = useRouter();
    // if token and user is set to local storage, fetch user and settings
    if (!localStorage.getItem("token") && !localStorage.getItem("user")) {
      router.push({ name: "Sign in" });
      return {};
    }

    const authStore = useAuth();
    authStore.fetchUser();

    const settings = useSettings();
    settings.fetchSettings();

    const categoryStore = useCategories();
    categoryStore.fetchCategories();

    const menuStore = useMenus();
    menuStore.fetchMenus();

    const optionStore = useOptions();
    optionStore.fetchOptions();

    const productStore = useProducts();
    productStore.fetchProducts();

    const offerStore = useOffers();
    offerStore.fetchOffers();

    const customerStore = useCustomers();
    customerStore.fetchCount();
    customerStore.fetchCustomers(1, 10);
    customerStore.fetchReviews(1);

    const staffStore = useStaffs();
    staffStore.fetchCount();
    staffStore.fetchStaffs(1, 10);

    const saleStore = useSales();
    saleStore.fetchCount();
    saleStore.fetchSales(1, 10);

    provide("messaging", messaging);

    token.then((token) => {
      // eslint-disable-next-line
      // @ts-ignore
      ApiService.post("/v1/auth/firebase-token", { token });
    });

    onMounted(() => {
      nextTick(() => {
        initializeComponents();
      });
    });

    return {
      router,
    };
  },
});
</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";

// Main demo style scss
// @import "assets/sass/plugins";
@import "assets/sass/style";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";

// override default styles
.el-form .el-form-item {
  display: flex;
  flex-direction: column;
  text-align: left;
  .el-form-item__label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.075rem;
    padding-top: calc(0.75rem + 1px);
    padding-bottom: calc(0.75rem + 1px);
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.5;
    color: #3f4254;
  }
}

.el-form .el-form-item.is-required .el-form-item__label {
  &:after {
    content: "";
    display: inline-block;
    background-color: #f1416c !important;
    border-radius: 6px;
    width: 8px;
    height: 4px;
    flex-shrink: 0;
    margin-left: 5px;
  }

  &::before {
    display: none !important;
  }
}
</style>
